import React from "react";
import icon01 from "../../assets/img/icon/h2_choose_icon01.svg";
import icon02 from "../../assets/img/icon/h2_choose_icon02.svg";
import icon03 from "../../assets/img/icon/h2_choose_icon03.svg";
import icon04 from "../../assets/img/icon/h2_choose_icon04.svg";
import WhyChooseUsTwoItem from "./WhyChooseUsTwoItem";

const SaleStratege = () => {
  const items = [
    {
      src: icon01,
      title: "Mining module",
      desc: "With our unique mining module, users of our application can directly mine our coins using their mobile devices. The interface is easy-to-use and fully integrated into our system. We have eliminated the need for specialized hardware or technical expertise, allowing effortless mining with just one click from anywhere and at any time.",
      url: "https://coin7.org/mining-module/",
    },
    {
      src: icon02,
      title: "Advertising module",
      desc: "Our advertising module provides a default revenue share of 50% to our app users for all advertising income generated within the app. Through this module, users have the opportunity to participate in our company's revenue stream passively and reap the benefits of their engagement.",
      url: "https://coin7.org/advertising-module/",
    },
    {
      src: icon03,
      title: "Wallet module",
      desc: "To ensure users have a convenient and secure way to manage their cryptocurrency holdings, we have integrated our wallet module as a key feature in the project. Our wallet module offers unique advantages that set it apart from other wallets on the market, such as reliable security measures and an intuitive user interface.",
      url: "https://coin7.org/wallet-module/",
    },
   
    {
      src: icon04,
      title: "MLM module",
      desc: "Using our MLM module, app users have the opportunity to generate multiple income streams by building their networks and recommending the app to their friends and colleagues. With this innovative module, users can easily expand their earning potential and benefit from additional revenue streams.",
      url: "https://coin7.org/mlm-module/",
    },
  ];

  return (
    <section className="choose-area-two choose-bg pb-130">
      <div className="container custom-container-four">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center mb-70">
              <span className="sub-title">OUR MODULES</span>
              {/* <h2 className="title">Why you choose our Coin7&nbsp;Token</h2> */}
            </div>
          </div>
        </div>

        <div className="row">
          {items.map((x, index) => (
            <div key={index} className="col-lg-3 col-sm-6">
              <WhyChooseUsTwoItem item={x} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SaleStratege;
