import React from "react";
import RoadmapTwoItem from "./RoadmapTwoItem";

const RoadmapTwo = () => {
  const roadmap_items = [
    {
      roadmapTitle: "Planning and Preparation",
      title: "Planning and Preparation",
      text1: "⦁	Define business goals and create a whitepaper",
      text2: "⦁	Choose a suitable blockchain platform and develop smart contracts",
      text3: "⦁	Gather an experience team and prepare the marketing plan"
    },
    {
      roadmapTitle: "Presale",
      title: "Presale",
      text1: "⦁	Launch the presale, and marketing campaigns to generate interest",
      text2: "⦁	Establish partnerships and contacts in the cryptocurrency industry",
      text3: "⦁	Allocate presale funds towards development and marketing efforts"
    },
    {
      roadmapTitle: "Initial Coin Offering",
      title: "Initial Coin Offering",
      text1: "⦁	Conduct ICO to fund development and promote usage",
      text2: "⦁	Maintain marketing activities and advertise on relevant crypto platforms",
      text3: "⦁	Ensure compliance with legal regulations and transparency towards investors"
    },
  ];
  const roadmap_items2 = [
    {
      roadmapTitle: "Exchange Listing",
      title: "Exchange Listing",
      text1: "⦁	Register and submit to various exchanges for coin listing",
      text2: "⦁	Negotiate and finalize agreements for the listing",
      text3: "⦁	Publish the coin on exchanges and initiate trading"
    },
    {
      roadmapTitle: "Advanced Features",
      title: "Advanced Features",
      text1: "⦁	Implement features like staking, mining, and payment gateways",
      text2: "⦁	Improve security and user-friendliness of the platform",
      text3: "⦁	Expand the marketing activities and grow the user base"
    },
    {
      roadmapTitle: "Conclusion",
      title: "Conclusion",
      text1: "We are immensely proud of our collective achievements thus far and are eagerly looking forward",
      text2: "to further enhancing the platform. By investing in Coin7, you can become a part of our vision for",
      text3: "the future and help us pave the way for tomorrow's innovations."
    },
  ];

  return (
    <section id="roadmap" className="roadmap-area-two pt-130 pb-100">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title section-title-two text-center mb-65">
              <span className="sub-title">roadmap</span>
              <h2 className="title">
                Coin7 Strategy and <br />
                Project Plan
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="roadmap-wrap-two">
              {roadmap_items.map((x, index) => (
                <RoadmapTwoItem key={index} item={x} />
              ))}
            </div>

            <div className="roadmap-wrap-two bottom">
              {roadmap_items2.map((x, index) => (
                <RoadmapTwoItem key={index} item={x} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoadmapTwo;
