/* eslint-disable no-unused-vars */
import { getCrowdSaleContractInstance, getPresaleContractInstance, getUSDTContractInstance } from './index';
import { utils } from "ethers";
import siteConfig from '../configs/site.config';

async function getPresaleStage() {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.roundId().call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getCoin7Price() {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.lastTokenPrice().call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getCoin7NextPrice() {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.calculateNewPrice().call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getUserReferralCount(address) {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.userReferralCount(address).call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getPresaleRoundData(stage) {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.saleRounds(stage).call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getBalanceOfPresaleToken(address) {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.balanceOf(address).call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getPresalePhase() {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.currentPhase().call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getAllReferrals() {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.getUserAllReferrals().call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getReferralData(address, id) {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.referralData(address, id).call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getCoin7Amount(ethAmount) {
  let amount = utils.parseEther(ethAmount)
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.getCoin7Amount(amount).call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}
async function getEthAmount(coin7Amount) {
  let amount =utils.parseUnits(coin7Amount, 18)
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.getEthAmount(amount).call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function approveUSDT(value, address) {
  let amount =  utils.parseUnits(value, siteConfig.USDT_DECIMAL)
  try {
    const contractInstance = getUSDTContractInstance()
    const res = await contractInstance.methods.approve(siteConfig.PRESALE_CONTRACT_ADDRESS, amount).send({
      from: address
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function buyCoin7WithUSDT(address, value, referrer) {
  let amount = utils.parseUnits(value, siteConfig.USDT_DECIMAL)
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.buyTokensWithUSDT(amount, referrer).send({
      from: address
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function buyCoin7WithETH(address, value, referrer) {
  let price = value.toString();
  let cost = utils.parseEther(price)
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.buyTokensWithEth(referrer).send({
      from: address,
      value: cost
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function MigratePresaleToken(address) {
  try {
    const contractInstance = getCrowdSaleContractInstance()
    const res = await contractInstance.methods.migratePresaleToken().send({
      from: address
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

export {
  buyCoin7WithUSDT,
  buyCoin7WithETH,
  getPresaleStage,
  getCoin7Price,
  getPresaleRoundData,
  getCoin7Amount,
  getEthAmount,
  getCoin7NextPrice,
  approveUSDT,
  getAllReferrals,
  getBalanceOfPresaleToken,
  getReferralData,
  getUserReferralCount,
  getPresalePhase,
  MigratePresaleToken
}