// import { Link } from "react-router-dom";
import React from "react";

const SaleItem = (props) => {
  return (
    <div className="choose-item-two wow fadeInUp" data-wow-delay=".2s">
      <div className="choose-icon-two">
        <img src={props.item.src} alt={props.item.title} />
      </div>
      <div className="choose-content" style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '330px'}}>
        <h2 className="title">
          <h4 >{props.item.title}</h4>
        </h2>
        <p>{props.item.desc}</p>
      </div>
    </div>
  );
};

export default SaleItem;
