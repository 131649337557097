/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import Deposite from "../../pages/Deposit";
import ClipLoader from "react-spinners/ScaleLoader";
import { getBalanceOfPresaleToken, getCoin7NextPrice, getCoin7Price, getPresalePhase, getPresaleRoundData, getPresaleStage, getReferralData, getUserReferralCount, MigratePresaleToken } from "../../apis/presale";
import linkIcon from '../../assets/group_link.svg';
import siteConfig from '../../configs/site.config';
import { NotificationManager } from "react-notifications";
import cn from "classnames";
import Table from 'rc-table';
import { timeConverter } from "../../utility/Utils";

const CountDownTwo = (props) => {
  const { address, connect, loading } = props
  const startDate = React.useRef(Date.now());
  const [presaleStage, setPresaleStage] = useState(0);
  const [priceCoin7, setCoin7Price] = useState(0);
  const [priceCoin7InUSDT, setCoin7PriceInUSDT] = useState(0);
  const [nextPriceCoin7InUSDT, setNextCoin7PriceInUSDT] = useState(0);
  const [soldAmount, setSoldAmount] = useState(0);
  const [buyedAmount, setBuyedAmount] = useState(0);
  const [currentUsdt, setCurrentUsdt] = useState(0);
  const [totalUsdt, setTotalUsdt] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [timestamp, setTimeStamp] = useState(0);
  const [referrals, setReferrals] = useState([]);
  const [referralCount, setReferralCount] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [presalePhase, setPresalePhase] = useState(0);
  const [exchangeing, setExchangeing] = useState(false)
  const [timeOver, setTimeOver] = useState(false);

  const [k, setK] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setK((i) => !i);
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [timestamp])

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  useEffect(() => {
    getPresaleStage()
      .then((res) => {
        const value = parseInt(res);
        setPresaleStage(value);
      })
      .catch((err) => {
        console.log(err);
      })
    getPresalePhase()
      .then((res) => {
        const value = parseInt(res);
        setPresalePhase(value);
      })
      .catch((err) => {
        console.log(err);
      })

    getCoin7Price()
      .then((res) => {
        const price = parseFloat(res) / 10 ** 8;
        const priceUsdt = 1 / price;
        setCoin7Price(price);
        setCoin7PriceInUSDT(priceUsdt);
      })
      .catch((err) => {
        console.log(err);
      })
    getCoin7NextPrice()
      .then((res) => {
        const price = parseFloat(res) / 10 ** 8;
        const priceUsdt = (1 / price).toFixed(2);
        setNextCoin7PriceInUSDT(priceUsdt);
      })
      .catch((err) => {
        console.log(err);
      })

    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    if (address) {
      getBalanceOfPresaleToken(address)
        .then((res) => {
          const tokenBuyed = (res / 10 ** 18);
          setBuyedAmount(tokenBuyed);
        })
        .catch((err) => {
          console.log(err);
        })

      getUserReferralCount(address)
        .then((res) => {
          setReferralCount(res);
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }, [address])


  useEffect(() => {
    async function fetchData() {
      if (referralCount > 0) {
        let referralDatas = [];
        for (let i = 0; i < referralCount; i++) {
          try {
            const data = await getReferralData(address, i);
            const eth = (data.rewardETHAmount / 10 ** 18)
            const usdt = (data.rewardUSDTAmount / 10 ** siteConfig.USDT_DECIMAL);
            let value = 0;
            let type = "";
            if (eth > 0) {
              value = eth.toString();
              type = "ETH"
            } else {
              value = usdt.toString();
              type = "USDT"
            }
            const date = timeConverter(data.timeReferred);
            const name = data.whoReferred;
            let refer = {
              name,
              value,
              type,
              date,
              key: i
            };
            referralDatas.push(refer);
          } catch (error) {
            console.log(error)
          }
        }
        console.log(referralDatas)
        setReferrals(referralDatas)
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, [referralCount])

  useEffect(() => {
    getPresaleRoundData(presaleStage)
      .then((res) => {
        const today = new Date();
        const endDay = new Date(res.endTime * 1000);

        var difference = endDay - today;
        setTimeStamp(difference)
        const price = res.tokenPrice / 10 ** 8;
        const tokenSupply = res.tokenSupply / 10 ** 18;
        setTotalSupply(tokenSupply);
        const tokenBuyed = (res.tokensBuyed / 10 ** 18);
        setSoldAmount(tokenBuyed);

        const currentusdt = tokenBuyed * price;
        const totalUsdt = tokenSupply * price;
        const percent = currentusdt / totalUsdt * 100;
        setPercentage(percent);
        setTotalUsdt(totalUsdt);
        setCurrentUsdt(currentusdt);
      })
      .catch((err) => {
        console.log(err);
      })
    // eslint-disable-next-line
  }, [presaleStage])

  useEffect(() => {
    if (timestamp < 10) {
      setTimeOver(true);
    } else {
      setTimeOver(false);
    }
  }, [timestamp])

  const handleReload = () => {
    getPresaleStage()
      .then((res) => {
        const value = parseInt(res);
        setPresaleStage(value);
      })
      .catch((err) => {
        console.log(err);
      })

    getPresalePhase()
      .then((res) => {
        const value = parseInt(res);
        setPresalePhase(value);
      })
      .catch((err) => {
        console.log(err);
      })

    getCoin7Price()
      .then((res) => {
        const price = parseFloat(res) / 10 ** 8;
        const priceUsdt = 1 / price;
        setCoin7Price(price);
        setCoin7PriceInUSDT(priceUsdt);
      })
      .catch((err) => {
        console.log(err);
      })
    getCoin7NextPrice()
      .then((res) => {
        const price = parseFloat(res) / 10 ** 8;
        const priceUsdt = (1 / price).toFixed(2);
        setNextCoin7PriceInUSDT(priceUsdt);
      })
      .catch((err) => {
        console.log(err);
      })

    getPresaleRoundData(presaleStage)
      .then((res) => {
        const today = new Date();
        const endDay = new Date(res.endTime * 1000);

        var difference = endDay - today;
        setTimeStamp(difference)
        const price = res.tokenPrice / 10 ** 8;
        const tokenSupply = res.tokenSupply / 10 ** 18;
        const tokenBuyed = res.tokensBuyed / 10 ** 18;
        setSoldAmount(tokenBuyed);

        const currentusdt = tokenBuyed * price;
        const totalUsdt = tokenSupply * price;
        const percent = currentusdt / totalUsdt * 100;
        setPercentage(percent);
        setTotalUsdt(totalUsdt);
        setCurrentUsdt(currentusdt);
      })
      .catch((err) => {
        console.log(err);
      })

    if (address) {
      getBalanceOfPresaleToken(address)
        .then((res) => {
          const tokenBuyed = (res / 10 ** 18);
          setBuyedAmount(tokenBuyed);
        })
        .catch((err) => {
          console.log(err);
        })

      getUserReferralCount(address)
        .then((res) => {
          setReferralCount(res);
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }

  const handleReferralLink = () => {
    navigator.clipboard.writeText(siteConfig.SITE_URL + "/referral/" + address);
    NotificationManager.success('Success', "Copied your referral Link", 3000);
  }

  const handleGetReferrals = () => {
    handleReload()
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          <div className="time-count day">
            <span>{"00"}</span>Days
          </div>
          <div className="time-count hour">
            <span>{"00"}</span>Hours
          </div>
          <div className="time-count min">
            <span>{"00"}</span>Minutes
          </div>
          <div className="time-count sec">
            <span>{"00"}</span>Seconds
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="time-count day">
            <span>{days < 10 ? "0" + days : days}</span>Days
          </div>
          <div className="time-count hour">
            <span>{hours < 10 ? "0" + hours : hours}</span>Hours
          </div>
          <div className="time-count min">
            <span>{minutes < 10 ? "0" + minutes : minutes}</span>Minutes
          </div>
          <div className="time-count sec">
            <span>{seconds < 10 ? "0" + seconds : seconds}</span>Seconds
          </div>
        </>
      );
    }
  };

  const datas = {
    id: "headingFive",
    controls: "collapseFive",
    expanded: false,
    btnClass: "collapsed",
    contentClass: "",
    title: "Your Referral Reward History",
    details: `LessAccounting is 100% web-based, meaning it can be
    accessed from anywhere and there’s no software to
    install on your computer. You can easily use
    LessAccounting on your iPhone or any Android mobile
    device with our cloud accounting. That is what makes it
    the best small business accounting software options
    among accounting programs.`,
  }

  const columns = [
    {
      title: 'Who Referred',
      dataIndex: 'name',
      key: 'name',
      width: 450,
    },
    {
      title: 'Amount',
      dataIndex: 'value',
      key: 'value',
      width: 250,
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      width: 200,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 300,
    },
  ];

  const handleExhange = () => {
    setExchangeing(true)
    MigratePresaleToken(address)
      .then((res) => {
        NotificationManager.success('Success', "Migration Successed", 3000);
        setExchangeing(false)
        handleReload();
      })
      .catch((err) => {
        setExchangeing(false)
        NotificationManager.error('Failed', "Migration Failed", 3000);
      })
  }

  return (
    <section id="countdown" className="countdown-area-two">
      <div className="container custom-container-four">
        <div className="row">
          <div className="col-lg-12">
            <div className="countdown-wrap">
              {
                presalePhase > 2 ?
                  <>
                    <h1 className="title" style={{ marginBottom: 10 }}>Presale is over</h1>
                    <p style={{ color: 'gray' }}>Presale is over, you can't buy presale token(7PT) but can
                      migrate your presale tokens(7PT) to real tokens(Coin7) now.</p>
                    <p style={{ color: 'gray' }}>Coin7 address : {siteConfig.COIN7_CONTRACT_ADDRESS}</p>
                    <p style={{ color: 'gray' }}>Please add this Coin7 address to your wallet</p>
                    {
                      address &&
                      <p style={{ fontSize: 25, color: '#0f3ad7' }}>
                        You purchased {buyedAmount.toFixed(2)} 7PT
                      </p>
                    }
                  </>
                  :
                  <>
                    <h1 className="title" style={{ marginBottom: 10 }}>PRESALE STAGE {presaleStage + 1}</h1>
                    <h5 style={{ color: 'black' }}>1 USDT = {priceCoin7InUSDT.toFixed(2)} Coin7</h5>
                    <p style={{ color: 'gray' }}>Price: ${priceCoin7} Coin7</p>
                    <div id="countdown-gampang"></div>
                    <div className="banner-progress-wrap" style={{ marginBottom: '30px' }}>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${percentage}%` }}
                          aria-valuenow={percentage}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                      <p style={{ fontSize: 25, color: '#f54e4e' }}>
                        {soldAmount.toFixed(2)} / {totalSupply.toFixed(2)} Coin7 sold
                      </p>
                      <p style={{ color: '#05cf31' }}>USDT Raised ${currentUsdt.toFixed(2)} / ${totalUsdt.toFixed(2)}</p>
                      <p style={{ fontSize: 20, color: 'grey' }}>
                        Buy before price increase to 1 USDT = {nextPriceCoin7InUSDT} Coin7
                      </p>
                      {
                        address &&
                        <p style={{ fontSize: 25, color: '#0f3ad7' }}>
                          You purchased {buyedAmount.toFixed(2)} Coin7
                        </p>
                      }
                    </div>
                    <div className="custom-countdown-two">
                      <Countdown date={startDate.current + timestamp} renderer={renderer} key={k} />
                    </div>

                  </>
              }
              {
                timeOver ?
                  <h1 className="title" style={{ marginBottom: 10, marginTop: 50 }}>The Next Presale Stage Will Start Soon.</h1>
                  :
                  <>
                    {
                      presalePhase === 0 &&
                      <h1 className="title" style={{ marginBottom: 10, marginTop: 50 }}>PRESALE IS NOT STARTED</h1>
                    }
                    {
                      presalePhase === 1 &&
                      <>
                        {
                          !address ?
                            <a
                              className="btn btn-two"
                              style={{ margin: 'auto', marginTop: 40, height: '70px', display: 'flex', width: '200px', alignItems: 'center', justifyContent: 'center' }}
                              onClick={connect}
                            >
                              {
                                loading ?
                                  <div>
                                    <ClipLoader color={"#FFCA28"} loading={loading} cssOverride={override} size={10} />
                                  </div>
                                  :
                                  "Connect Wallet"
                              }
                            </a>
                            :
                            <Deposite priceCoin7InUSDT={priceCoin7InUSDT} handleReload={handleReload} />
                        }
                        {
                          address &&
                          <a
                            className="btn btn-three"
                            style={{ margin: 'auto', marginTop: 40, height: '50px', display: 'flex', width: '250px', alignItems: 'center', justifyContent: 'space-around', color: '#6610f2', padding: 0 }}
                            onClick={handleReferralLink}
                          >
                            <img src={linkIcon} alt="stake icon" style={{ width: '42px' }} />
                            10% Referral Link
                          </a>
                        }
                        {
                          address &&
                          <div className="accordion-item" style={{ width: '100%', margin: 'auto', marginTop: '20px' }}>
                            <h2 className="accordion-header" id={"referreal1"}>
                              <button
                                className={cn("accordion-button", datas.btnClass)}
                                type="button"
                                onClick={handleGetReferrals}
                                style={{ textAlign: 'center' }}
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapseFive`}
                                aria-expanded={datas.expanded}
                                aria-controls={datas.controls}
                              >
                                <h4 style={{ padding: 0, color: 'black', textAlign: 'center', width: '100%' }}>
                                  {datas.title}

                                </h4>
                              </button>
                            </h2>
                            <div
                              id={datas.controls}
                              className={cn("accordion-collapse collapse", datas.contentClass)}
                              aria-labelledby={datas.id}
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body" style={{ overflow: 'auto' }}>
                                <Table columns={columns} data={referrals} />
                              </div>
                            </div>
                          </div>
                        }

                      </>
                    }
                    {
                      presalePhase === 2 &&
                      <h1 className="title" style={{ marginBottom: 10, marginTop: 50 }}>PRESALE PAUSED</h1>
                    }
                    {
                      presalePhase > 2 &&
                      <>
                        {
                          !address ?
                            <a
                              className="btn btn-two"
                              style={{ margin: 'auto', marginTop: 40, height: '70px', display: 'flex', width: '200px', alignItems: 'center', justifyContent: 'center' }}
                              onClick={connect}
                            >
                              {
                                loading ?
                                  <div>
                                    <ClipLoader color={"#FFCA28"} loading={loading} cssOverride={override} size={10} />
                                  </div>
                                  :
                                  "Connect Wallet"
                              }
                            </a>
                            :
                            <a
                              className="btn btn-two"
                              style={{ margin: 'auto', marginTop: 40, height: '70px', display: 'flex', width: '200px', alignItems: 'center', justifyContent: 'center' }}
                              onClick={handleExhange}
                            >
                              {
                                exchangeing?
                                  <div>
                                    <ClipLoader color={"#FFCA28"} loading={exchangeing} cssOverride={override} size={10} />
                                  </div>
                                  :
                                  "Claim"
                              }
                            </a>
                        }
                        {
                          exchangeing && 
                          <h6 style={{color: 'black', marginTop: '10px'}}>please wait until transaction is completed.</h6>
                        }
                      </>
                    }

                  </>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CountDownTwo;
