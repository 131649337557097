import React from "react";
import PageLoader from "../components/PageLoader/PageLoader";
import cn from "classnames";
import HeaderTwo from "../components/Header/HeaderTwo";
import FooterTwo from "../components/Footer/FooterTwo";

const LayoutTwo = (props) => {
  const { address, connect, disconnectWallet } = props
  return (
    <div className={cn("white-background")}>
      <PageLoader />

      <HeaderTwo
        address={address}
        connect={connect}
        disconnectWallet={disconnectWallet}
      />

      {props.children}

      <FooterTwo />
    </div>
  );
};

export default LayoutTwo;
