import React, { useContext, useState } from 'react';
import classes from './Staking.module.css';
import ethIcon from '../../assets/Ethereum.png';
import usdtIcon from '../../assets/usdt-icon.webp';
import coin7Icon from '../../assets/topIcon.png';
import { approveUSDT, buyCoin7WithETH, buyCoin7WithUSDT, getCoin7Amount, getEthAmount } from '../../apis/presale';
import { AppContext } from '../../context/AppContext';
import { NotificationManager } from 'react-notifications';
import ClipLoader from "react-spinners/ScaleLoader";

const Staking = (props) => {
  const { page, priceCoin7InUSDT, handleReload } = props;
  const {
    account,
    getAccBalance,
    getAccUSDTBalance,
    referrer
  } = useContext(AppContext);

  const [data, setData] = useState({
    eth: "",
    usdt: "",
    coin7InETH: "",
    coin7InUSDT: "",
  })

  const [loading, setLoading] = useState(false);

  const inputChangeHandler = async (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setData((data) => ({
      ...data,
      [name]: value
    }))

    switch (name) {
      case "eth":
        getCoin7Amount(value)
          .then((res) => {
            const result = res / 10 ** 18 / 10 ** 18;
            setData((data) => ({
              ...data,
              'coin7InETH': result.toFixed(12)
            }))
          })
          .catch((err) => {
            console.log(err);
          })
        break;
      case "usdt":
        const coin7Amount = priceCoin7InUSDT * value;
        setData((data) => ({
          ...data,
          'coin7InUSDT': coin7Amount
        }))
        break;
      case "coin7InETH":
        getEthAmount(value)
          .then((res) => {
            const result = res / 10 ** 18 / 10 ** 18;
            setData((data) => ({
              ...data,
              'eth': result.toFixed(12)
            }))
          })
          .catch((err) => {
            console.log(err);
          })
        break;
      case "coin7InUSDT":
        const usdtAmount = value / priceCoin7InUSDT;
        setData((data) => ({
          ...data,
          'usdt': usdtAmount.toFixed(6)
        }))
        break;

      default:
        break;
    }
  };

  const goMaxETH = async () => {
    const balance = await getAccBalance()
    setData((data) => ({
      ...data,
      'eth': balance
    }))

    getCoin7Amount(balance.toString())
      .then((res) => {
        const result = res / 10 ** 18 / 10 ** 18;
        setData((data) => ({
          ...data,
          'coin7InETH': result
        }))
      })
      .catch((err) => {
        console.log(err);
      })
  };
  const goMaxUSDT = async () => {
    const balance = await getAccUSDTBalance()
    setData((data) => ({
      ...data,
      'usdt': balance
    }))
    const coin7Amount = priceCoin7InUSDT * balance;
    setData((data) => ({
      ...data,
      'coin7InUSDT': coin7Amount
    }))
  };

  const handleBuyWithEth = () => {
    if (data.eth > 0) {
      setLoading(true);
      buyCoin7WithETH(account, data.eth, referrer)
        .then((res) => {
          NotificationManager.success('Success', `You buyed ${data.coin7InETH} Coin7`, 3000);
          setLoading(false);
          handleReload();
        })
        .catch((error) => {
          NotificationManager.warning('Warning', error.message, 3000);
          console.log(error.message);
          setLoading(false);
        })
    } else {
      NotificationManager.warning('Warning', "ETH is required to Buy", 6000);
    }
  }

  const handleBuyWithUSDT = () => {
    if (data.usdt >= 30) {
      setLoading(true);
      approveUSDT(data.usdt, account)
        .then((res) => {
          buyCoin7WithUSDT(account, data.usdt, referrer)
            .then((res) => {
              NotificationManager.success('Success', `You buyed ${data.coin7InUSDT} Coin7`, 3000);
              setLoading(false);
              handleReload();
            })
            .catch((error) => {
              NotificationManager.warning('Warning', error.message, 3000);
              console.log(error.message);
              setLoading(false);
            })
        })
        .catch((err) => {
          console.log(err)
          setLoading(false);
        })
    } else {
      NotificationManager.warning('Warning', "A minimum amount of 30 USDT. less man could not buy", 6000);
    }
  }

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };


  return (
    <div className={classes.Staking}>
      <h3 style={{ paddingTop: 20 }}> Buy with {page === 1 ? "ETH" : "USDT"}</h3>
      <div className={classes.inputDiv}>
        <p style={{ paddingLeft: '40px', margin: 0, textAlign: 'left' }}>
          Amount in <b>{page === 1 ? "ETH" : "USDT"}</b> you pay
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
          {
            page === 1 ?
              <>
                <img src={ethIcon} alt="stake icon" className={classes.stakeIcon} />
                <input
                  className={classes.input}
                  style={{ paddingRight: '70px' }}
                  type="number"
                  min="0"
                  name="eth"
                  value={data.eth}
                  onChange={inputChangeHandler}
                />
                <button className='btn btn-one' style={{ position: 'absolute', right: 0, height: '39px', width: '66px', padding: '11px 10px 22px 10px', borderRadius: '10px' }} onClick={goMaxETH}>
                  MAX
                </button>
              </>
              :
              <>
                <img src={usdtIcon} alt="stake icon" className={classes.stakeIcon} />
                <input
                  className={classes.input}
                  style={{ paddingRight: '70px' }}
                  type="number"
                  min="0"
                  name="usdt"
                  value={data.usdt}
                  onChange={inputChangeHandler}
                />
                <button className='btn btn-one' style={{ position: 'absolute', right: 0, height: '39px', width: '66px', padding: '11px 10px 22px 10px', borderRadius: '10px' }} onClick={goMaxUSDT}>
                  MAX
                </button>
              </>
          }
        </div>
      </div>
      <div className={classes.inputDiv} style={{ marginTop: '10px' }}>
        <p style={{ paddingLeft: '40px', margin: 0, textAlign: 'left' }}>
          Amount in <b>Coin7</b> you receive
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
          <img src={coin7Icon} alt="stake icon" className={classes.stakeIcon} />
          {
            page === 1 ?
              <input
                className={classes.input}
                type="number"
                min="0"
                step="1"
                name="coin7InETH"
                onChange={inputChangeHandler}
                value={data.coin7InETH}
              />
              :
              <input
                className={classes.input}
                type="number"
                min="0"
                step="1"
                name="coin7InUSDT"
                onChange={inputChangeHandler}
                value={data.coin7InUSDT}
              />
          }
        </div>
      </div>
      {
        page === 1 ?
          <button
            className={classes.stakeButton}
            onClick={handleBuyWithEth}
          >
            {
              loading ?
                <div>
                  <ClipLoader color={"#FFCA28"} loading={loading} cssOverride={override} size={10} />
                </div>
                :
                <h4 style={{ margin: 0 }}>Buy</h4>
            }
          </button>
          :
          <button
            className={classes.stakeButton}
            onClick={handleBuyWithUSDT}
          >
            {
              loading ?
                <div>
                  <ClipLoader color={"#FFCA28"} loading={loading} cssOverride={override} size={10} />
                </div>
                :
                <h4 style={{ margin: 0 }}>Buy</h4>
            }
          </button>
      }
      {
        loading && 
        <h6>please wait until transaction is completed.</h6>
      }
    </div>
  );
};
//My balance: 504304.394968082 TestToken (Tst)
export default Staking;
