import React from "react";

const RoadmapTwoItem = (props) => {
  return (
    <div className="roadmap-item" style={{ marginLeft: '-10px' }}>
      <span className="roadmap-title">{props.item.roadmapTitle}</span>
      <div className="roadmap-content" style={{paddingTop: '30px', minHeight: '290px'}}>
        <span className="dot"></span>
        <h4 className="title" style={{whiteSpace: 'pre'}}>{props.item.title}</h4>
        <div style={{ display: 'flex', flexDirection: 'column'}}>
          <p style={{width: '300px'}}> {props.item.text1} </p>
          <p style={{width: '300px'}}> {props.item.text2} </p>
          <p style={{width: '300px'}}> {props.item.text3} </p>
        </div>
      </div>
    </div>
  );
};

export default RoadmapTwoItem;
