import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import WOW from "wow.js";
import Home from "./pages/Home";
import { useLocation } from "react-router-dom";
import {NotificationContainer} from 'react-notifications'
import 'react-notifications/lib/notifications.css'
function App() {
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
     <NotificationContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path='/referral/:id' element={<Home />} />
      </Routes>
    </>
  );
}

export default App;
