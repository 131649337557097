import React from "react";
import icon01 from "../../assets/img/icon/h2_choose_icon01.svg";
import icon02 from "../../assets/img/icon/h2_choose_icon02.svg";
import icon03 from "../../assets/img/icon/h2_choose_icon03.svg";
// import icon04 from "../../assets/img/icon/h2_choose_icon04.svg";
import SaleItem from "./saleitem";
// import WhyChooseUsTwoItem from "./WhyChooseUsTwoItem";

const WhyChooseUsTwo = () => {
  const items = [
    {
      src: icon01,
      title: "Token Presale Period",
      desc: "The Coin7 token will be based on ERC20, with a total supply of 10,000,000,000 tokens.   During the presale period, 10% of the tokens will be sold.   The presale will be divided into 10 phases, with each phase having a 5% price increase.   The starting price will be 0.01 cents, and the token will be listed on exchanges at a price of 0.015 cents.",
      url: "https://coin7.org/mining-module/",
    },
    {
      src: icon02,
      title: "Exchange Listing & Token Release ",
      desc: "An additional 10% of the total token supply will be used for the exchange listing.  50% of the tokens generated will be locked for 2 years.   The remaining 30% of the tokens will be released for trading once a certain number of app users is reached. For every 1 million users, 1% of the tokens will be released for trading.",
      url: "https://coin7.org/advertising-module/",
    },
    {
      src: icon03,
      title: "Wallet module",
      desc: "10% of the tokens will be sold in 10 phases with 5% price increases.  An additional 10% will be used for the exchange listing. 50% will be locked for 2 years. The remaining 30% will be released for trading once a certain number of app users is reached. The presale will start at 0.01 cents and list on exchanges at 0.015 cents.",
      url: "https://coin7.org/wallet-module/",
    },
  ];

  return (
    <section className="choose-area-two choose-bg pb-130">
      <div className="container custom-container-four">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center mb-70">
              <span className="sub-title">Tokensale Stages</span>
              {/* <h2 className="title">Why you choose our Coin7&nbsp;Token</h2> */}
            </div>
          </div>
        </div>

        <div className="row" style={{justifyContent: 'space-around'}}>
          {items.map((x, index) => (
            <div key={index} className="col-lg-3 col-sm-6">
              <SaleItem item={x} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUsTwo;
