
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import { NotificationManager } from "react-notifications";
import siteConfig from '../../configs/site.config';
import { AppContext } from "../../context/AppContext";
import ClipLoader from "react-spinners/ScaleLoader";

const CheckNetwork = (props) => {
  const {
    switchNetwork
  } = useContext(AppContext);

  const [loading, setLoading] = useState(false)

  const handleSwitchNetwork = () => {
    setLoading(true);
    switchNetwork()
      .then((res) => {
        setLoading(false);
      })
      .catch((error) => {
        NotificationManager.warning('Warning', error.message, 3000);
        setLoading(false);
      })
  }

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  return (
    <section id="countdown" className="countdown-area-two">
      <div className="container custom-container-four">
        <div className="row">
          <div className="col-lg-12">
            <div className="countdown-wrap">
              <h1 className="title" style={{ marginBottom: 10 }}>You are in Wrong Network</h1>
              <h5 style={{ color: 'black' }}>Our Presale Token is Deployied to {siteConfig.NETWORK.NAME} </h5>
              <p style={{ color: 'gray' }}>Please change your network</p>
              <a
                className="btn btn-two"
                style={{ margin: 'auto', marginTop: 40, height: '70px', display: 'flex', width: '200px', alignItems: 'center', justifyContent: 'center' }}
                onClick={handleSwitchNetwork}
              >
                {
                  loading ?
                    <div>
                      <ClipLoader color={"#FFCA28"} loading={loading} cssOverride={override} size={10} />
                    </div>
                    :
                    "Switch your Network"
                }
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CheckNetwork;
