import React from "react";
// import emailjs from 'emailjs-com';

const ContactTwoForm = () => {

  function sendEmail(e) {
    e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

    // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
    //   .then((result) => {
    //       window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
    //   }, (error) => {
    //       console.log(error.text);
    //   });
  }

  return (
    <div className="contact-form-wrap-two wow fadeInRight" data-wow-delay=".2s">
      <h2 className="title">Lets Get in touch with us</h2>
      <form onSubmit={sendEmail}>
        <input type="text" placeholder="Name" required />
        <input type="email" placeholder="Email Address" required />
        <textarea name="massage" placeholder="Massage" required></textarea>
        <button type="submit" className="btn btn-two">
          Send Massage
        </button>
      </form>
    </div>
  );
};

export default ContactTwoForm;
