import React from "react";
import { Link } from "react-router-dom";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const WhyChooseUsItem = (props) => {
  return (
    <div className="choose-item">
      <div className="choose-icon">
        <CircularProgressbar value={props.item.percentage} text={`${props.item.percentage}%`} />
      </div>
      <div className="choose-content">
        <h2 className="title">
          <Link to={props.item.link}>{props.item.title}</Link>
        </h2>
        <p>{props.item.description}</p>
      </div>
    </div>
  );
};

export default WhyChooseUsItem;
