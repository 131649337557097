// import { Link } from "react-router-dom";
import React from "react";

const WhyChooseUsTwoItem = (props) => {
  return (
    <div className="choose-item-two wow fadeInUp" data-wow-delay=".2s">
      <div className="choose-icon-two">
        <img src={props.item.src} alt={props.item.title} />
      </div>
      <div className="choose-content" style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '390px'}}>
        <h2 className="title" style={{ whiteSpace: 'pre' }}>
          <h4 >{props.item.title}</h4>
        </h2>
        <p>{props.item.desc}</p>
        <a
          href="https://coin7.org/what-is-coin7/" target="_blank" rel="noopener noreferrer"
          className="btn btn-one mt-2"
          style={{ width: '150px', height: '38px', padding: '11px 28px', background: '#002a55' }}
        >
          Read More
        </a>
      </div>
    </div>
  );
};

export default WhyChooseUsTwoItem;
