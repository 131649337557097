import React from "react";
import aboutImg from "../../assets/img/images/h2_about_img.png";
import investImg from "../../assets/img/images/invest.png";
import techImg from "../../assets/img/images/tech.png";
import shape01 from "../../assets/img/images/about_shape01.png";
import shape02 from "../../assets/img/images/about_shape02.png";

const About = () => {

  return (
    <section id="about" className="about-area-two">
      <div className="container custom-container-four">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div
              className="about-img-two text-center wow fadeInLeft"
              data-wow-delay=".2s"
            >
              <img src={aboutImg} alt="" style={{ height: '350px' }} />
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="about-content-two wow fadeInRight"
              data-wow-delay=".2s"
            >
              <div className="section-title section-title-two mb-15">
                <span className="sub-title">ABOUT Coin7</span>
                <h3 className="title" style={{fontSize: '32px'}}>
                  What is Coin7
                </h3>
              </div>
              <p>
                Coin7 is a project, that aims to revolutionize the trade of goods and services by building the world’s first marketplace based on cryptocurrencies. The project provides a secure, fast, and cost-effective alternative to traditional payment methods. With its MLM and advertising cashback module, users can generate extra income and actively shape the platform. The mining …
              </p>
              <a
                href="https://coin7.org/what-is-coin7/" target="_blank" rel="noopener noreferrer"
                className="btn btn-one mb-2"
                style={{ width: '150px', height: '38px', padding: '11px 28px', background: '#1a3551' }}
              >
                Read More
              </a>

            </div>
          </div>
        </div>
        <div className="row align-items-center mt-5">
          <div className="col-lg-6">
            <div
              className="about-img-two text-center wow fadeInLeft"
              data-wow-delay=".2s"
            >
              <img src={investImg} alt="" style={{ height: '300px', borderRadius: '13px' }} />
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="about-content-two wow fadeInRight"
              data-wow-delay=".2s"
            >
              <div className="section-title section-title-two mb-15">
                <h3 className="title"  style={{fontSize: '32px'}}>
                  Investment Token Coin7
                </h3>
              </div>
              <p>
                Our investment token is called Coin7 and will be created on the Ethereum blockchain. During our presale mode, only 10% of the tokens will be offered at a price of $0.01 per token. An additional 10% of the coins will be available after listing on an exchange. We have intentionally chosen not to release any …
              </p>
              <a
                href="https://coin7.org/939-2/" target="_blank" rel="noopener noreferrer"
                className="btn btn-one mb-2"
                style={{ width: '150px', height: '38px', padding: '11px 28px', background: '#1a3551' }}
              >
                Read More
              </a>

            </div>
          </div>
        </div>
        <div className="row align-items-center mt-5">
          <div className="col-lg-6">
            <div
              className="about-img-two text-center wow fadeInLeft"
              data-wow-delay=".2s"
            >
              <img src={techImg} alt="" style={{ height: '300px', borderRadius: '13px' }} />
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="about-content-two wow fadeInRight"
              data-wow-delay=".2s"
            >
              <div className="section-title section-title-two mb-15">
                <h3 className="title"  style={{fontSize: '32px'}}>

                  Investment Strategy for Coin7 Token Presale Earnings
                </h3>
              </div>
              <p>
                Coin7 is an innovative blockchain-based platform that aims to revolutionize the way people invest in digital assets. Our plan is to allocate a substantial portion of the capital generated from the presale towards the project, to fund the platform’s growth. We believe that investing the majority of the presale earnings back into the project is …
              </p>
              <a
                href="https://coin7.org/investment-strategy-for-coin7-token-presale-earnings/" target="_blank" rel="noopener noreferrer"
                className="btn btn-one mb-2"
                style={{ width: '150px', height: '38px', padding: '11px 28px', background: '#1a3551' }}
              >
                Read More
              </a>

            </div>
          </div>
        </div>
        <div className="row align-items-center mt-5">
          <div className="col-lg-6">

          </div>
          <div className="col-lg-6">
            <a
              href="https://www.coin7.org/whitepaper.pdf/" target="_blank" rel="noopener noreferrer"
              className="btn btn-two"
            >
              View Whitepaper
            </a>

          </div>
        </div>
      </div>

      <div className="about-shape-wrap">
        <img src={shape01} alt="" className="shape-one" />
        <img src={shape02} alt="" className="shape-two rotateme" />
      </div>
    </section>
  );
};

export default About;
