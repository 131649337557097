import React, { useState } from 'react';
import classes from './Deposit.module.css';
import Staking from '../components/Deposit/Staking';
import Navigation from '../components/Deposit/Navigation';

const Deposite = (props) => {
  const {priceCoin7InUSDT, handleReload} = props;
  const [page, setPage] = useState(1);


  const changePage = () => {
    if (page === 1) {
      setPage(2);
    } else if (page === 2) {
      setPage(1);
    }
  };


  return (
    <div className={classes.Grid}>
      <div className={classes.loader}></div>
      <div className={classes.Child}>
        <Navigation changePage={changePage} />
        <div>
          <Staking
            page={page}
            priceCoin7InUSDT={priceCoin7InUSDT}
            handleReload={handleReload}
          />
        </div>
      </div>
    </div>
  );
};

export default Deposite;
